
import {
  PropType,
  defineComponent,
  onBeforeUnmount,
  reactive,
  ref,
  toRefs,
  computed,
} from 'vue';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

import { useGtag } from 'vue-gtag-next';

import {
  videoFieldDefault,
  pushDataLayer,
} from '@/utils/GTMCustomDataLayers/media';
import { VideoField, MediaStatusEnum } from '@/utils/GTMCustomDataLayers/types';
import { Video } from '@/types';
import anime from 'animejs/lib/anime.es';

const EVENTS = {
  VIDEO_LOADED: 'videoLoaded',
  VIDEO_FINISHED: 'videoFinished',
  VIDEO_ENDS: 'videoEnds',
  VIDEO_FRAGMENT_LISTENED: 'videoFragmentListened',
};

export default defineComponent({
  components: {},
  props: {
    video: Object as PropType<Video>,
    percentageConsideredAsSkip: {
      type: Number,
      default: 5,
    },
    checkpointAmountToAwardPoints: {
      type: Number,
      default: 9, // if starting from 0% then out of 11, if from 10 then it's out of 10
    },
    idRef: {
      type: String,
      default: null,
    },
    customStoryBar: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    customizedVideoControlOverlay: {
      type: Boolean,
      default: false,
    },
    hideVideoControls: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    EVENTS.VIDEO_LOADED,
    EVENTS.VIDEO_FINISHED,
    EVENTS.VIDEO_ENDS,
    EVENTS.VIDEO_FRAGMENT_LISTENED,
  ],
  setup(props, { emit }) {
    const state = reactive({
      activeTrack: props.video.videoUrl,
      pointsSentForActiveTrack: false,
      lastPercentage: 0,
      currentMediaStatus: '',
      lastPlayedTime: 0,
      sentTrackingPercentage: 0,
    });
    const { event } = useGtag();
    const isCustomSeeking = ref(false);
    const customMediaStatus = ref(MediaStatusEnum.PLAY);
    const videoDuration = ref(0);
    const timeline = ref();
    const isMobileInteraction = ref();

    timeline.value = anime.timeline({
      autoplay: false,
      loop: false,
      duration: 0,
      easing: 'linear',
    });

    // reference to DOM element
    const refVideoPlayer = ref(null);
    const videoTrackingName = computed(
      () =>
        `Video ${props.video.name} | Phillip Morris Germany`
    );

    const prepareAndSendGTMObject = (newStatus, currentTime, duration) => {
      const videoGTMObject: VideoField = {
        ...videoFieldDefault,
        videoCurrentTime: currentTime,
        videoPercent: getPlayedTimePercentage(duration, currentTime),
        videoStatus: newStatus,
      };
      pushDataLayer('videoMedia', { videoMedia: videoGTMObject });
    };

    const onCanPlay = () => {
      const { duration, currentTime } = refVideoPlayer.value;

      if (!state.currentMediaStatus) {
        videoFieldDefault.videoDuration = duration;
        videoFieldDefault.videoTitle = videoTrackingName.value;

        prepareAndSendGTMObject(MediaStatusEnum.PLAY, currentTime, duration);

        state.currentMediaStatus = MediaStatusEnum.PLAY;
      }
    };

    const onPlay = () => {
      if (!refVideoPlayer.value) return;
      const { duration, currentTime, seeking } = refVideoPlayer.value;

      const videoStatusHandler = {
        pause: () => {
          // if video is resumed after pause
          if (!seeking) {
            prepareAndSendGTMObject(
              MediaStatusEnum.PLAY,
              currentTime,
              duration
            );
            state.currentMediaStatus = MediaStatusEnum.PLAY;
            customMediaStatus.value = MediaStatusEnum.PLAY;
          }
        },
        play: () => {
          // if video is directly skipped
          prepareAndSendGTMObject(
            MediaStatusEnum.SKIP,
            state.lastPlayedTime,
            duration
          );
          prepareAndSendGTMObject(
            MediaStatusEnum.RESUME_AFTER_SKIP,
            currentTime,
            duration
          );

          state.currentMediaStatus = MediaStatusEnum.PLAY;
          state.lastPlayedTime = currentTime;
        },
        skip: () => {
          // if video is resumed after skipped
          prepareAndSendGTMObject(
            MediaStatusEnum.SKIP,
            state.lastPlayedTime,
            duration
          );
          prepareAndSendGTMObject(
            MediaStatusEnum.RESUME_AFTER_SKIP,
            currentTime,
            duration
          );

          state.currentMediaStatus = MediaStatusEnum.PLAY;
          state.lastPlayedTime = currentTime;
        },
        default: () => void 0,
      };

      const pushMediaGTMObject =
        videoStatusHandler[state.currentMediaStatus] ||
        videoStatusHandler['default'];

      pushMediaGTMObject();
    };

    const onPause = () => {
      const { duration, currentTime, seeking } = refVideoPlayer.value;

      if (!seeking) {
        customMediaStatus.value = MediaStatusEnum.PAUSE;
        state.currentMediaStatus = MediaStatusEnum.PAUSE;
        state.lastPlayedTime = currentTime;
        prepareAndSendGTMObject(MediaStatusEnum.PAUSE, currentTime, duration);
      }
    };

    const onSeeking = throttle(() => {
      isCustomSeeking.value = true;
      // state.currentMediaStatus = MediaStatusEnum.SKIP;
    }, 1000);

    const onSeeked = debounce(() => {
      isCustomSeeking.value = false;
      state.currentMediaStatus = MediaStatusEnum.SKIP;
    }, 3000);

    const onProgressMedia = () => {
      if (!refVideoPlayer.value) return;
      const { duration, currentTime, volume, muted, paused, seeking } =
        refVideoPlayer.value;

      timeline.value.seek(refVideoPlayer.value.currentTime * 1000);

      const percentage = (currentTime * 100) / duration;

      const flooredPercentage = Math.floor(percentage);
      const hasSound = volume > 0 && !muted;

      // track video % while playing
      if (
        flooredPercentage % 10 === 0 &&
        state.sentTrackingPercentage !== flooredPercentage
      ) {
        prepareAndSendGTMObject(MediaStatusEnum.PLAYING, currentTime, duration);
        state.sentTrackingPercentage = flooredPercentage;
      }

      if (
        !isCustomSeeking.value &&
        flooredPercentage > 0 &&
        flooredPercentage % 10 === 0 &&
        !props.video.progress[String(flooredPercentage)] &&
        hasSound
      ) {
        emit(EVENTS.VIDEO_FRAGMENT_LISTENED, {
          progress: flooredPercentage,
          trackAction: props.video.trackingAction,
        });
        // We need to send only once every 10% the GA tracking event
        event('Video listen percentage', {
          event_category: videoTrackingName.value,
          event_label: `watched up to ${flooredPercentage}%`,
        });
      }

      // Award points when percentage above margin
      if (
        Object.values(props.video.progress).reduce(
          (acc, item) => acc + (item ? 1 : 0),
          0
        ) >= props.checkpointAmountToAwardPoints &&
        !state.pointsSentForActiveTrack &&
        hasSound
      ) {
        state.pointsSentForActiveTrack = true;
        emit(EVENTS.VIDEO_FINISHED, props.video);
      }

      const skippedAmountPercentage = Math.abs(
        percentage - state.lastPercentage
      );
      // Track when user skipped above margin
      if (skippedAmountPercentage >= props.percentageConsideredAsSkip) {
        event('Video skip', {
          event_category: videoTrackingName.value,
          event_label: `skipped ${skippedAmountPercentage}%`,
        });
      }
      state.lastPercentage = percentage;
      if (!paused && !seeking) {
        state.lastPlayedTime = currentTime;
      }
    };

    const getPlayedTimePercentage = (duration, currentTime) => {
      const timePercentage = Math.floor((currentTime / duration) * 100);
      return timePercentage;
    };

    const onMediaEnds = () => {
      prepareAndSendGTMObject(
        MediaStatusEnum.COMPLETED,
        videoFieldDefault.videoDuration,
        videoFieldDefault.videoDuration
      );
      emit(EVENTS.VIDEO_ENDS, { pointsSent: state.pointsSentForActiveTrack });
    };

    const onVideoMetadataLoaded = () => {
      videoDuration.value = refVideoPlayer.value.duration;
      emit(EVENTS.VIDEO_LOADED);
      if(props.customStoryBar) {
        timeline.value.add({
          targets: document.querySelectorAll('.story-bar__item')[0].children[0],
          width: '100%',
          duration: refVideoPlayer.value.duration * 1000,
        });
      }
    };

    onBeforeUnmount(() => {
      timeline.value.seek(0);
    });

    const onInteractCustomOverlay = (event, clickType) => {
      if (
        !event ||
        (isMobileInteraction.value && clickType !== isMobileInteraction.value)
      )
        return;
      isMobileInteraction.value = clickType;

      if (customMediaStatus.value === MediaStatusEnum.PAUSE) {
        refVideoPlayer.value.play();
        customMediaStatus.value = MediaStatusEnum.PLAY;
      } else {
        refVideoPlayer.value.pause();
        customMediaStatus.value = MediaStatusEnum.PAUSE;
      }
    };

    return {
      ...toRefs(state),
      timeline,
      MediaStatusEnum,
      refVideoPlayer,
      videoDuration,
      currentMediaStatus: state.currentMediaStatus,
      customMediaStatus,
      onVideoMetadataLoaded,
      onProgressMedia,
      onMediaEnds,
      onSeeked,
      onSeeking,
      onPlay,
      onPause,
      onCanPlay,
      onInteractCustomOverlay,
    };
  },
});
